<template>
    <div class="engineering-quantity-alert">
        <div class="engineering-quantity-alert-box">
            <h1 class="engineering-head">
                <span></span>
                <span>数据钻取分析</span>
                <span class="iconfont" @click="closeChildAlert">&#xe67d;</span>
            </h1>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane name="1">
                    <span slot="label"><i class="el-icon-date"></i> 是否重大工程</span>
                    <majorProjects :select-time-data="selectTimeData" v-if="activeName == activeIndex"></majorProjects>
                </el-tab-pane>
                <!-- <el-tab-pane name="2">
                    <span slot="label"><i class="el-icon-date"></i> 开工状态</span>
                    <commenceStatus :select-time-data="selectTimeData" v-if="activeName == activeIndex"></commenceStatus>
                </el-tab-pane> -->
                <el-tab-pane name="2">
                    <span slot="label"><i class="el-icon-date"></i> 区域</span>
                    <byRegion :select-time-data="selectTimeData" v-if="activeName == activeIndex"></byRegion>
                </el-tab-pane>
                <el-tab-pane name="3">
                    <span slot="label"><i class="el-icon-date"></i> 形象进度</span>
                    <imageProgress :select-time-data="selectTimeData" v-if="activeName == activeIndex"></imageProgress>
                </el-tab-pane>
                <el-tab-pane name="4">
                    <span slot="label"><i class="el-icon-date"></i> 施工部位</span>
                    <constructionSite :select-time-data="selectTimeData" v-if="activeName == activeIndex"></constructionSite>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import majorProjects from './majorProjects'; // 重大工程
// import commenceStatus from './commenceStatus'; // 开工状态
import byRegion from './byRegion'; // 按区域
import imageProgress from './imageProgress'; // 按形象进度
import constructionSite from './constructionSite'; // 按施工部位
export default {
    data() {
        return {
            activeName: '1',
            activeIndex: '1',
        };
    },
    props: {
        selectTimeData: {
            type: Array,
        },
    },
    components: {
        byRegion,
        // commenceStatus,
        constructionSite,
        imageProgress,
        majorProjects,
    },
    methods: {
        closeChildAlert() {
            this.$emit('closeChildAlert');
        },
        handleClick(tab) {
            setTimeout(() => {
                this.activeIndex = Number(tab.index) + 1;
            });
        },
    },
};

</script>
<style lang='stylus'>
.engineering-quantity-alert
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .engineering-quantity-alert-box
        width 90%

        background #fff
        height 90%
        min-height 8.2rem
        max-height 90%
        .engineering-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
        .el-tabs__nav
            widtd 100%
            padding-left .2rem
            div:hover
                color #D6000F
            div:first-child
                margin-left .2rem
            .is-active
                color #D6000F
            .el-tabs__active-bar
                background-color #D6000F
</style>